<template>
	<div id="case" class="case" ref="case">
		<!-- 头部 nav导航 -->
		<header-nav :tab="true"></header-nav>
		<!-- 工程案例-->
		<!-- 小屏 -->
		<div class="case-bglogo" v-if="is_vertical">
			<img src="/images/case-bg.png" style="width: 100%;height: 100%;"/>
		</div>
		<div class="uni-flex-column uni-flex-center">
			<div class="case-content">
				<div class="casebox">
					<div class="case-box" v-for="(item,index) in caseList" :key="index" @click="goDetail(item)">
						<div class="case-logo">
							<img :src="item.thumbnail" style="width: 100%;height: 100%;"/>
						</div>
						<div class="case-label">{{item.title}}</div>
					</div>
				</div>
			</div>
			
			<div class="foot-page">
				<div class="page-box" @click="goList(-1)">
					<img src="/images/left.png" style="width: 50%;height: 50%;"/>
				</div>
				<div class="page-num" v-for="(item,index) in totalPages" :key="index" v-if="index<5" @click="goList(index+1)">{{index+1}}</div>
				<div class="page-box" @click="goList(-2)">
					<img src="/images/right.png" style="width: 50%;height: 50%;"/>
				</div>
			</div>
		</div>
		
		<!-- 底部链接 -->
		<!-- 友情链接 -->
		<section class="friend uni-flex-column uni-flex-center" style="margin-top: 30px;">
			<div class="friend-content">
				<div class="friend-text">
					<span>友情链接：</span>
					<a href="https://shop117115684.taobao.com/?spm=a2142.8996326.sellercard.15.146f2be3VrqbMR">千雪制冷淘宝店</a>
				</div>
			</div>
		</section>
		<!-- 联系我们 -->
		<!-- 小屏 -->
		<section class="foot uni-flex-column uni-flex-center" ref="contact" v-if="is_vertical">
			<div class="foot-content">
				<div class="foot-logo">
					<img src="/images/qrcode.png" style="width: 100%;height: 100%;"/>
				</div>
				<div class="foot-scan">微信扫一扫</div>
				<div class="foot-text">
					<span>copyright © 2020-2021 ALL RIGHT RESERVED 宁德市千雪制冷设备有限公司 版权所有</span>
					<span>地址：宁德市蕉城区古溪新村23号</span>
					<span>备案号：闽ICP备20014190号</span>
					<span>电话：13616088271</span>
				</div>
			</div>
		</section>
		<!-- 大屏 -->
		<section class="foot uni-flex-column uni-flex-center" ref="contact" v-else>
			<div class="foot-content">
				<div class="foot-text">
					<span>copyright © 2020-2021 ALL RIGHT RESERVED 宁德市千雪制冷设备有限公司 版权所有</span>
					<span>地址：宁德市蕉城区古溪新村23号</span>
					<span>备案号：闽ICP备20014190号</span>
					<span>电话：13616088271</span>
				</div>
				<div class="foot-logo">
					<img src="/images/qrcode.png" style="width: 100%;height: 100%;"/>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
	import api from '@/utils/api.js';
	import headerNav from '@/components/header.vue';
	export default {
		name: 'case', // 工程案例
		components: {
			headerNav,
		},
		data() {
			return {
				is_vertical: false,  //false 小屏  true 大屏
				win_width: document.body.clientWidth,
				body_width: '',
				body_height: '',
				//工程案例
				caseList: [],
				search: {
					categoryId: 2,
					current: 1,
					size: 10,
				},
				totalPages: 1,
			}
		},
		computed: {
			
		},
		created() {
			window.scrollTo({
			    left: 0,
			    top: 0,
			    behavior: 'smooth'
			})
			this.search.current = 1;
			this.api_menuList();
		},
		mounted() {
			/* 获取页面高宽 */
			// this.body_width = document.getElementById('case').offsetWidth;
			// this.body_height = document.getElementById('case').clientHeight;
			const that = this;
			window.onresize = () => {
				return (() => {
					window.screenWidth = document.body.clientWidth;
					that.win_width = window.screenWidth;
				})();
			};
			console.log(this.win_width);
			if (this.win_width < 768) {
				//小屏
				this.is_vertical = true;
			} else if (this.win_width >= 768) {
				//大屏
				this.is_vertical = false;
			}
			
		},
		watch: {
		},
		methods: {
			api_menuList() {
				api.menuList().then(res=>{
					console.log(res);
					if(res.code == 0){
						res.data.forEach(item => {
							if(item.title == "工程案例") {
								this.search.categoryId = item.id;
								this.api_caseList();
							}
						});
					}
				})
			},
			api_caseList() {
				api.newsList(this.search.categoryId,this.search).then(res=>{
					console.log(res);
					if(res.code == 0){
						this.caseList = res.data.records;
						this.totalPages = res.data.pages;
					}
				})
			},
			
			goList(val) {
				if(val == -1) {  //向后退一页
					if(this.search.current>1) {
						this.search.current--;
						this.api_caseList()
					}
				}
				else if(val == -2) {  //向前进一页
					if(this.search.current<this.totalPages) {
						this.search.current++;
						this.api_caseList()
					}
				}
				else {
					this.search.current = val;
					this.api_caseList()
				}
				
			},
			goDetail(item) {
				this.$router.push({
					name: 'newsDetail',
					query:{
						id:item.id
					}
				})
			},
		}
	}
</script>

<style scoped="" lang="less">
	.yx-h5 {
		font-size: 14px;
	}

	.case {
		height: 100%;
		overflow: auto;
		font-size: 20px;
		color: #bdbdbd;
		background-color: #FFFFFF;
	}
	
	.case-bglogo {
		width: 100%;
		height: 300px;
	}
	
	.case-content {
		width: 1200px;
		margin-bottom: 50px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		
		.casebox {
			display: flex;
			flex-wrap: wrap; 
			justify-content: space-between;
			width: 100%;
			
			.case-box {
				padding: 30px 0;
				
				.case-logo {
					width: 300px;
					height: 260px;
				}
				
				.case-label {
					margin-top: 50px;
					width: 300px;
					height: 60px;
					border: 2px solid #E9E9E9;
					display: flex;
					justify-content: center;
					align-items: center;
					color: #646464;
					font-size: 18px;
				}
			}
		}
	
	}

	.foot-page {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 50px 0 20px;
		font-size: 20px;
		color: #0E82D9;
		
		.page-box {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 60px;
			height: 40px;
			margin: 5px;
			background: #F2F2F2;
			border-radius: 6px;
			&:hover {
				background-color: #0E82D9;
			}
		}
		
		.page-num {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 40px;
			height: 40px;
			margin: 5px;
			background: #F2F2F2;
			border-radius: 6px;
			
			&:hover {
				background-color: #0E82D9;
				color: #FFFFFF;
			}
		}
	}
	
	.friend {
		// margin-top: 30px;
		height: 50px;
		background-color: #F5F5F5;
		
		.friend-content {
			display: flex;
			flex-direction: wrap;
			width: 1200px;
			
			.friend-text {
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #000000;
				line-height: 24px;
			}
		}
	}
	
	.foot {
		height: 200px;
		background-color: #0E82D9;
		
		.foot-content {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 1200px;
			
			.foot-text {
				display: flex;
				flex-direction: column;
				text-align: left;
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 24px;
				.span {
				}
			}
			
			.foot-logo {
				width: 142px;
				height: 142px;
			}
		}
	}

	/* 手机端 */
	@media (orientation:portrait) and (max-width:890px) {
		
		.case {
			overflow-y: auto;
			overflow-x: hidden;
		}
		
		.case-content {
			width:100% !important;
			margin: 30px !important;
			.casebox {
				display: flex;
				flex-wrap: wrap; 
				// justify-content: space-around;
				// padding: 0 15px;
				width: 95%;
				
				.case-box {
					padding: 10px 5px;
					.case-logo {
						width: 150px;
						height: 100px;
					}
					
					.case-label {
						margin-top: 20px;
						width: 150px;
						height: 40px;
						font-size: 14px;
					}
				}
			}
			
		}
		
		.foot-page {
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 50px 0 20px;
			font-size: 14px;
			color: #0E82D9;
			
			.page-box {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 40px;
				height: 40px;
				margin: 5px;
				background: #F2F2F2;
				border-radius: 6px;
			}
			
			.page-num {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 40px;
				height: 40px;
				margin: 5px;
				background: #F2F2F2;
				border-radius: 6px;
				
				&:hover {
					background-color: #0E82D9;
					color: #FFFFFF;
				}
			}
		}
		
		.friend {
			margin-top: 30px;
			height: 30px;
			background-color: #F5F5F5;
			
			.friend-content {
				display: flex;
				flex-direction: wrap;
				width: 100%;
				margin-left: 10px;
				.friend-text {
					font-size: 14px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #000000;
					line-height: 20px;
				}
			}
		}
		
		.foot {
			// margin-top: 30px;
			height: 370px;
			background-color: #0E82D9;
			
			.foot-content {
				display: flex;
				flex-direction: column;
				width: 100%;
				.foot-scan {
					padding: 20px 0;
					text-align: center;
					font-size: 12px;
					color: #FFFFFF;
				}
				.foot-text {
					margin-top: 20px;
					text-align: center;
					font-size: 12px;
				}
				
				.foot-logo {
					width: 120px;
					height: 120px;
				}
			}
		}

		.header .content .nav {
			display: none !important;
		}

		.content {
			justify-content: space-between !important;
			padding: 0 20px;

			.clear {
				display: none;
			}

			.nav-min {
				display: flex !important;
			}

			.langs {
				display: none;
			}
		}
	}
</style>
